<template>
	<div class="transcript-html">
		<div class="transcript-html__wrap">
			<div :class="{
				'transcript-html__holder': true,
				'transcript-html__holder--showing-all': showingAll
			}">
				<perfect-scrollbar
					:options="{
						suppressScrollX: true
					}"
					ref="scroll"
				>
					<ul class="transcript-html__list">
						<li class="transcript-html__item" v-for="item in transcript">
							<div class="transcript-html__author">
								<p v-text="item.author"/>
							</div>
							<div class="transcript-html__text" v-html="item.text"/>
						</li>
					</ul>
				</perfect-scrollbar>
			</div>
			<more title="Читать" @show="showAll"/>
		</div>
	</div>
</template>

<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import More from '../components/More'
import json from './json/transcript.json'

export default {
	components: {
		PerfectScrollbar,
		More
	},
	methods:    {
		showAll() {
			this.showingAll = !this.showingAll;
		}
	},
	data() {
		return {
			showingAll: false,
			transcript: json.data
		}
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import "../assets/scss/config";

.transcript-html {
	margin: 0 (-$grid-gutter-half);

	@media screen and ($media-mobile) {
		margin: 0;
	}

	&__wrap {
		width: math.div(11 * 100%, 12);
		margin: 0 math.div(1 * 100%, 12) 0 0;

		@media screen and ($media-mobile) {
			width: auto;
			margin: 0;
		}
	}

	&__holder {
		@media screen and ($media-mobile) {
			max-height: 30vh;
			overflow: hidden;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 50%;
				width: 100%;
				background: linear-gradient(180deg, rgba($color-light, 0) 0%, rgba($color-light, 1) 100%);
			}

			&--showing-all {
				max-height: none;

				&:after {
					content: none;
				}
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;

		@media screen and ($media-mobile) {
			margin: 0 16px;
		}
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;
		margin: 0 0 24px;

		&:last-child {
			margin: 0;
		}

		@media screen and ($media-mobile) {
			display: block;
		}
	}

	&__author {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(3 * 100%, 11);

		p {
			font: 18px/24px 'RF Devi Semi Bold', sans-serif;
			margin: 0 0 0 32px;
			opacity: .5;

			@media screen and ($media-mobile) {
				font: 18px/24px 'RF Devi Bold', sans-serif;
				display: inline;
				margin: 0 4px 0 0;
				opacity: 1;
			}
		}

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(4 * 100%, 11);
		}

		@media screen and ($media-mobile) {
			flex: unset;
			display: inline;
			padding: 0;
		}
	}

	&__text {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(7 * 100%, 11);
		margin: 0 math.div(1 * 100%, 11) 0 0;

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(6 * 100%, 11);
		}

		@media screen and ($media-mobile) {
			flex: unset;
			display: inline;
			margin: 0;
			padding: 0;
		}

		p {
			font: 18px/24px 'RF Devi Semi Bold', sans-serif;
			margin: 0 0 12px;

			&:last-child {
				margin: 0;
			}

			@media screen and ($media-mobile) {
				display: inline;
			}
		}
	}
}
</style>