<template>
  <div>
    <p>
      Настоящий документ (далее – Условия) определяет порядок обработки персональных данных лиц, желающих принять участие в мероприятии
      «Прямая Линия с Владимиром Путиным» (далее – Мероприятие) способами, установленными настоящими Условиями, включая пользователей интернет-сайта
      (далее - Интернет-ресурс):
    </p>
    <p><em>- moskva-putinu.ru (москва-путину.рф) и его поддомена video.moskva-putinu.ru.</em></p>
    <p>Операторами, осуществляющим обработку персональных данных (далее – Операторы), являются:</p>
    <table border="0" cellpadding="0" cellspacing="0">
      <tr>
        <td width="50%" valign="top">
          <em
          >при обращении по видеосвязи и через Интернет-ресурсы:
            москва-путину.рф, moskva-putinu.ru и его поддомену
            video.moskva-putinu.ru, в том числе при помощи приложения
            «Москва-Путину»</em
          >
        </td>
        <td width="50%" valign="top">
          Федеральное государственное унитарное предприятие «Всероссийская
          государственная телевизионная и радиовещательная компания» (ФГУП
          «ВГТРК»); ОГРН 1027700310076; адрес местонахождения: 125040, г.
          Москва, вн.тер.г. муниципальный округ Беговой, ул. 5-я Ямского поля,
          д. 19-21, стр. 1
        </td>
      </tr>
      <tr>
        <td valign="top">
          <em
          >при обращении по телефону 8 (800) 200-40-40 и (или) посредством
            направления SMS/MMS по номеру 0-40-40</em
          >
        </td>
        <td valign="top">
          Публичное акционерное общество «Ростелеком» (ПАО «Ростелеком»); ОГРН
          1027700198767; адрес местонахождения: Российская Федерация, 191167, г.
          Санкт-Петербург, вн.тер.г. муниципальный округ Смольнинское, наб.
          Синопская, д. 14, лит. А; почтовый адрес: 115172, Российская
          Федерация, Москва, ул. Гончарная, д. 30, стр. 1
        </td>
      </tr>
    </table>
    <ol>
      <li>
        Лицом, желающим принять участие в мероприятии (далее также – Субъект персональных данных), является лицо, вносящее регистрационные данные
        в соответствующую форму, размещенную на Интернет-ресурсе, и (или) лицо, обратившееся по видеосвязи и (или) по телефону 8 (800) 200-40-40
        и (или) посредством направления SMS/MMS по номеру 0-40-40.
      </li>
      <li>
        Операторами обрабатываются данные, предоставляемые Субъектом персональных данных, в том числе: фамилия, имя, отчество; пол; дата рождения, место рождения;
        адрес регистрации, адрес проживания; гражданство; семейное положение; данные о детях; образование; сведения о трудовой деятельности; фотографии;
        номер контактного телефона; адрес электронной почты; паспортные данные и другие данные – в случае их предоставления.
      </li>
      <li>
        Пользователь отвечает за достоверность предоставленных данных и самостоятельно несет риски наступления любых неблагоприятных последствий в случае
        предоставления чужих и (или) недостоверных данных.
      </li>
      <li>
        Персональные данные обрабатываются в целях обеспечения участия Субъекта персональных данных в Мероприятии, в том числе предоставления к Интернет-ресурсу,
        подготовки и направления пользователю ответов на его запросы, а также для сбора статистических данных, проведения исследований и анализа таких данных.
      </li>
      <li>
        Действия, совершаемые с персональными данными: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
        обезличивание, передача (предоставление, доступ), блокирование, удаление. Персональные данные обрабатываются с использованием средств автоматизации.
      </li>
      <li>
        Для достижения целей, указанных в пункте 4 Условий, Персональные данные могут указанным передаваться Операторами друг другу, а также иным третьим лицам:
        <ul>
          <li>органы государственной власти Российской Федерации, органы государственной власти субъектов Российской Федерации, органы местного самоуправления;</li>
          <li>Общероссийское общественное движение «НАРОДНЫЙ ФРОНТ «ЗА РОССИЮ» (ОГРН 11377990166954);</li>
          <li>Акционерное общество «Центр хранения данных» (ОГРН 1247700651461);</li>
          <li>Акционерное общество «Московский центр новых технологий телекоммуникаций» (ОГРН 1027739152418);</li>
          <li>Акционерное общество «Центр биометрических технологий» (ОГРН 1227700243748);</li>
          <li>Публичное акционерное общество «Сбербанк России» (ОГРН 1027700132195);</li>
          <li>Общество с ограниченной ответственностью «Центр речевых технологий» (ОГРН 1027810243295).</li>
        </ul>
      </li>
      <li>
        Операторы соблюдают конфиденциальность персональных данных и обеспечивают безопасность персональных данных при их обработке, а также соблюдают применимые
        требования законодательства РФ.
      </li>
      <li>
        Операторы обеспечивают запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации
        с использованием баз данных, находящихся на территории Российской Федерации, если иное не предусмотрено требованиями законодательства РФ.
      </li>
      <li>Операторы не осуществляют трансграничную передачу персональных данных.</li>
      <li>
        Персональные данные обрабатываются до достижения цели обработки и в течение 3 (трех) лет с указанного момента, если иной срок обработки не следует из
        требований законодательства РФ.
      </li>
      <li>
        Пользователь в любой момент вправе направить Операторам заявление о прекращении обработки (отозвать согласие на обработку) персональных данных путем
        направления заявления в письменной форме по адресу (в зависимости от формы обращения для участия в Мероприятии):
        <ol>
          <li>для ФГУП «ВГТРК»: 115162, г. Москва, ул. Шаболовка, д. 37.</li>
          <li>для ПАО «Ростелеком»: 115172, Российская Федерация, Москва, ул. Гончарная, д. 30, стр. 1.</li>
        </ol>
        При этом пользователь признает и соглашается с тем, что с момента направления заявления о прекращении обработки (отзыва согласия на обработку)
        участие в Мероприятии станет невозможным или будет ограничено.
      </li>
      <li>Пользователь возмещает Операторам убытки, причиненные в случае нарушения положений пункта 3 Условий.</li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>
