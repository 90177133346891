<template>
	<div :class="className">
		<div class="control__anons" v-html="anons"/>
		<div class="control__menu">
			<ask-question type="desktop"/>
			<ul class="control__list">
				<li
					:class="`control__item control__item--${item.color} control__item--${item.type}`"
					v-for="item in controlMenu"
				>
					<component
						:is="item.type !== 'ask' ? 'router-link' : 'span'"
						:to="`/${item.type}`"
					>
						<div class="control__box">
							<h2 class="control__title">
								{{ item.title }}
							</h2>
							<div class="control__pictures" v-if="item.pictures && item.pictures.length">
								<div class="control__picture" v-for="picture in item.pictures">
									<img
										:src="picture"
										alt="Picture"
									>
								</div>
							</div>
							<div class="control__info" v-if="item.subtype">
								<component :is="item.subtype"/>
							</div>
							<div
								class="control__info"
								v-if="item.info"
								v-html="item.info"
							/>
							<div class="control__buttons" v-if="item.buttons && item.buttons.length">
								<router-link
									class="control__button"
									v-for="button in item.buttons"
									v-text="button.title"
									:to="`/${button.view}`"
								/>
							</div>
						</div>
					</component>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AskQuestion from '../components/Ask'

export default {
	components: {
		AskQuestion
	},
	computed:   {
		...mapGetters([
			'currentArticle',
			'controlMenu'
		]),
		className() {
			let classes = [ 'control' ];

			if (this.currentArticle === 'home') {
				classes.push('control--active');
			} else {
				classes.push('control--hidden');
			}

			if (this.controlMenu.length % 3 === 1) {
				classes.push('control--one');
			}

			if (this.controlMenu.length % 3 === 2) {
				classes.push('control--two');
			}

			return classes.join(' ');
		}
	},
	methods:    {
		...mapActions([
			'setArticle'
		])
	},
	data() {
		return {
			anons: '<p>19 декабря в 12:00 по московскому времени в прямом эфире телеканалов «Первый», «Россия 1», «Россия 24», НТВ, ОТР, «Мир», радиостанций «Маяк», «Вести FM» и «Радио России» пройдет специальная программа «Итоги года с Владимиром Путиным».</p>',
		}
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import "../assets/scss/config";

.control {
	box-sizing: border-box;
	margin: 150px $grid-gutter-page 64px;
	display: flex;
	flex-flow: row nowrap;
	position: relative;
	z-index: 2;

	@media screen and ($media-desktop) {
		flex-flow: column nowrap;
    margin-top: 120px;
	}

	@media screen and ($media-mobile) {
		display: none;
	}

	&__anons {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(3 * 100%, 12);

		@media screen and ($media-desktop) {
			flex: 0 1 auto;
		}

		p {
			font: 16px/20px 'RF Devi Semi Bold', sans-serif;
			margin: 0 40px 27px 0;
			padding: 0;
		}
	}

	&__menu {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(9 * 100%, 12);

		@media screen and ($media-desktop) {
			margin: 36px 0 0;
			flex: 0 1 auto;
		}
	}

	&__list {
		display: flex;
		flex-flow: row wrap;
		margin: 0 (-$grid-gutter-half);
		padding: 0;
		list-style: none;

		.control__item--rules,
		.control__item--privacy {
			display: none;
		}
	}

	&__item {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(1 * 100%, 3);
		margin: 0 0 24px;

		@media screen and ($media-tablet) {
			flex: 0 0 math.div(1 * 100%, 2);
		}

		.control--one &:last-child {
			flex: 0 0 math.div(3 * 100%, 3);

			@media screen and ($media-tablet) {
				flex: 0 0 math.div(1 * 100%, 2);
			}
		}

		.control--two &:last-child {
			flex: 0 0 math.div(2 * 100%, 3);

			@media screen and ($media-tablet) {
				flex: 0 0 math.div(1 * 100%, 2);
			}
		}
	}

	&__box {
		width: 100%;
		padding-bottom: 144%;
		border-radius: 16px;
		position: relative;
		overflow: hidden;

		.control--one .control__item:last-child & {
			padding-bottom: 45.6444%;

			@media screen and ($media-tablet) {
				padding-bottom: 144%;
			}
		}

		.control--two .control__item:last-child & {
			padding-bottom: 69.34%;

			@media screen and ($media-tablet) {
				padding-bottom: 144%;
			}
		}

		.control__item--red & {
			background: rgba($color-red, .9);
			color: $color-white;
		}

		.control__item--blue & {
			background: rgba($color-blue, .9);
			color: $color-white;
		}

		.control__item--dark & {
			background: rgba($color-grey, .98);
			color: $color-white;
		}

		.control__item--light & {
			background: rgba($color-light, .9);
			color: $color-grey;
		}

		.control__item--questions & {
			&:after {
				content: '';
				position: absolute;
				bottom: 32px;
				left: 0;
				width: 100%;
				height: 100%;
				background: transparent url(../assets/images/questions-count.svg) 32px bottom no-repeat;
				z-index: 1;
			}
		}

		.control__item--archive & {
			&:after {
				content: '';
				position: absolute;
				bottom: 32px;
				left: 0;
				width: 100%;
        height: 60%;
        background: transparent url(../assets/images/archive.svg) 32px bottom no-repeat;
				z-index: 1;
			}
		}

    .control__item--videos & {
      &:after {
        content: '';
        position: absolute;
        bottom: 32px;
        left: 0;
        width: 100%;
        height: 60%;
        background: transparent url(../assets/images/video.svg) 32px bottom no-repeat;
        z-index: 1;
      }
    }

		.control__item--transcript & {
			//&:after {
			//  content: '';
			//  position: absolute;
			//  bottom: 0;
			//  left: 0;
			//  width: 100%;
			//  height: 60%;
			//  background: linear-gradient(to bottom, rgba(45,59,87,0) 33%,rgba(45,59,87,1) 71%);
			//  z-index: 3;
			//}
		}
	}

	&__title {
		font: 32px/32px 'RF Devi Semi Bold', sans-serif;
		color: inherit;
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		padding: 27px 32px;
		margin: 0;
		z-index: 3;

		@media screen and ($media-tablet) {
			font: 24px/24px 'RF Devi Semi Bold', sans-serif;
			letter-spacing: -0.01em;
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	&__info {
		box-sizing: border-box;
		position: absolute;
		top: 35%;
		left: 0;
		width: 100%;
		height: auto;
		padding: 0 32px;
		font: 16px/24px 'RF Devi Semi Bold', sans-serif;
		z-index: 3;

		strong {
			font: 16px/24px 'RF Devi Bold', sans-serif;
		}

		.control__item--transcript & {
			//max-height: 35%;
			//overflow: hidden;
			background: -webkit-linear-gradient(rgba(45, 59, 87, 1) 29%, rgba(45, 59, 87, 0) 67%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.control__item--ask & {
			top: auto;
			bottom: 32px;
		}
	}

	&__pictures {
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 3;

		.control__item--live & {
			padding-bottom: 56.25%;
		}

		.control__item--videos & {
			display: flex;
			flex-flow: row wrap;
			padding: 0 24px 24px;
		}
	}

	&__picture {
		.control__item--live & {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.control__item--videos & {
			box-sizing: border-box;
			flex: 0 1 50%;
			padding: 8px;
			position: relative;

			img {
				border-radius: 8px;
				width: 100%;
				height: auto;
				vertical-align: top;
			}
		}
	}

	&__buttons {
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 32px 32px;
		display: flex;
		flex-flow: column nowrap;

		.control__item--live & {
			bottom: 40%;
		}

		.control__item--transcript & {
			z-index: 4;
		}
	}

	&__button {
		-webkit-appearance: none;
		border: none;
		outline: 0;
		cursor: pointer;
		font: 16px/48px 'RF Devi Semi Bold', sans-serif;
		text-align: center;
		color: $color-grey;
		background: $color-white;
		border-radius: 8px;
		margin: 0 0 8px;
		text-decoration: none;

		&:last-child {
			margin: 0;
		}

		.control__item--live & {
			height: 56px;
			width: 56px;
			border-radius: 56px;
			font-size: 0;
			background: transparent url(../assets/images/play-line.svg) center / cover no-repeat;
		}
	}
}
</style>