import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from './pages/Home'
import Archive from './pages/Archive'
import Ask from './pages/Ask'
import Live from './pages/Live'
import Questions from './pages/Questions'
import Transcript from './pages/Transcript'
import Videos from './pages/Videos'
import Privacy from './pages/Privacy'
import Rules from './pages/Rules'

const isLocal           = process.env['LOCAL_MODE'] === 'on';
const DEFAULT_PAGE_TITLE = 'Итоги года с Владимиром Путиным.'

console.log(isLocal)

const routes = [
	{
		path:      '/',
		name:      'home',
		component: Home,
		meta:      {
			title: DEFAULT_PAGE_TITLE
		}
	},
	{
		path:      '/archive',
		name:      'archive',
		component: Archive,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Архив`
		}
	},
	{
		path:      '/ask',
		name:      'ask',
		component: Ask,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Задать вопрос`
		}
	},
	{
		path:      '/live',
		name:      'live',
		component: Live,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Прямой эфир`
		}
	},
	{
		path:      '/questions',
		name:      'questions',
		component: Questions,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Вопросы`
		}
	},
	{
		path:      '/transcript',
		name:      'transcript',
		component: Transcript,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Стенограмма`
		}
	},
	{
		path:      '/videos',
		name:      'videos',
		component: Videos,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Видео`
		}
	},
	{
		path:      '/privacy',
		name:      'privacy',
		component: Privacy,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Условия обработки персональных данных`
		}
	},
	{
		path:      '/rules',
		name:      'rules',
		component: Rules,
		meta:      {
			title: `${ DEFAULT_PAGE_TITLE } Правила проведения информационной компании`
		}
	}
];

export default createRouter({
	history: isLocal ? createWebHashHistory() : createWebHistory(),
	routes
})