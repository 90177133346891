<template>
	<div class="mobile-control">
		<h2 class="mobile-control__anons" v-text="anons"/>
<!--		<ul class="mobile-control__list" v-if="buttons && buttons.length">-->
<!--			<li class="mobile-control__item" v-for="button in buttons">-->
<!--				<a-->
<!--					v-if="button.type === 'phone'"-->
<!--					class="mobile-control__button"-->
<!--					v-text="button.title"-->
<!--					:href="`tel:${button.value}`"-->
<!--				/>-->
<!--			</li>-->
<!--		</ul>-->
	</div>
</template>

<script>
export default {
	data() {
		return {
			anons:   'Программа выйдет в эфир 19 декабря в 12.00 по московскому времени.',
			buttons: [
				// {
				// 	title: 'Смотреть эфир'
				// },
				{
					title: '8-800-200-40-40',
					value: 88002004040,
					type:  'phone'
				},
				// {
				// 	title: 'Написать'
				// }
			]
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/config";

.mobile-control {
	display: none;

	@media screen and ($media-mobile) {
		display: block;
		position: relative;
		z-index: 2;
	}

	&__anons {
		font: 16px/24px 'RF Devi Semi Bold', sans-serif;
		margin: 58px 16px 26px;
	}

	&__list {
		margin: 0 16px 256px;
		padding: 0;
		list-style: none;
	}

	&__item {
		margin: 0 0 4px;

		&:last-child {
			margin: 0;
		}
	}

	&__button {
		-webkit-appearance: none;
		text-decoration: none;
		background: $color-red;
		font: 16px/48px 'RF Devi Semi Bold', sans-serif;
		color: $color-white;
		text-align: center;
		display: block;
		border-radius: 8px;
	}
}
</style>