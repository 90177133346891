<template></template>

<script>
import { mapActions } from 'vuex'

export default {
	methods: {
		...mapActions([
			'setArticle'
		])
	},
	async mounted() {
		let name = this.$route.name;

		document.title = this.$route.meta.title;

		await this.setArticle({
			type: name
		})

		let liveFrame  = window.frames?.live_player;
		let videoFrame = window.frames?.video_player;

		let questionFrames = [ ...document.querySelectorAll('.questions-html__frame') ].map($frame => {
			return window.frames[$frame.name];
		})

		if (questionFrames && questionFrames.length) {
			for (let frame of questionFrames) {
				frame.postMessage('pause', '*');
			}
		}

		if (liveFrame) {
			liveFrame.postMessage('pause', '*');
		}

		if (videoFrame) {
			videoFrame.postMessage('pause', '*');
		}
	}
}
</script>