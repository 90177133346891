<template>
  <div class="popup">
    <span class="popup__close" @click="hide"></span>
    <div class="popup__body">
      <h2 v-text="caption"></h2>
      <div class="popup__content">
        <privacy v-if="popupPage === 'privacy'" />
        <rules v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Privacy from "../data/content/privacy-text";
import Rules from "../data/content/rules-text";

export default {
  components: {
    Privacy,
    Rules
  },
  computed: {
    ...mapGetters(["currentArticle", 'popupPage']),
    caption() {
      if (this.popupPage === "privacy") {
        return "Условия обработки персональных данных";
      }
      return "Правила проведения информационной кампании\n" +
          "в рамках организации мероприятия\n" +
          "«Прямая линия с Владимиром Путиным»\n";
    }
  },
  methods: {
    ...mapActions(["showPopup"]),
    hide() {
      this.showPopup({ type: false });
    },
  },
};
</script>

<style lang="scss">
.popup {
    align-items: center;
    background: hsla(0, 0%, 94%, .98);
    border-radius: 16px;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: calc(100vh - 20px);
    position: fixed;
    right: 5px;
    top: 5px;
    left: 5px;
    bottom: 5px;
    z-index: 999;


  &__close {
    background: transparent url(../assets/images/close-dark.svg) center / cover
      no-repeat;
    display: block;
    height: 40px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 40px;
    cursor: pointer;
  }

  &__body {
    padding: 115px 20px 0;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  &__content {
    overflow: auto;
    height: 80%;

    h2 {
      margin-bottom: 10px;
    }
  }
}
</style>
