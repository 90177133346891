<template>
	<div :class="className">
		<button
			class="more__button"
			v-text="title"
			@click.prevent="show"
		/>
	</div>
</template>

<script>
export default {
	props:    [
		'title'
	],
	computed: {
		className() {
			let classNames = [ 'more' ];

			classNames.push(`more--${ this.isHidden ? 'hidden' : 'visible' }`);

			return classNames.join(' ');
		}
	},
	methods:  {
		show() {
			this.isHidden = !this.isHidden;
			this.$emit('show');
		}
	},
	data() {
		return {
			isHidden: false
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/config";

.more {
	padding: 32px 16px 0;
	flex-flow: row nowrap;

	&--visible {
		display: none;

		@media screen and ($media-mobile) {
			display: flex;
		}
	}

	&--hidden {
		display: none;
	}

	&__button {
		-webkit-appearance: none;
		outline: none;
		border: none;
		cursor: pointer;
		flex: 0 1 100%;
		font: 16px/48px 'RF Devi Semi Bold', sans-serif;
		background: $color-white;
		text-align: center;
		border-radius: 8px;
	}
}
</style>