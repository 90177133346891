<template></template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'setArticle'
    ])
  },
  async mounted() {
    let name  = this.$route.name;

    document.title = this.$route.meta.title;

    await this.setArticle({
      type: name
    });

  }
}
</script>