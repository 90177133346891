<template>
	<div class="video-html">
		<div class="video-html__player" v-if="videos && videos.length">
			<iframe
				name="video_player"
				allow="autoplay; encrypted-media; fullscreen; clipboard-read; clipboard-write"
				allowfullscreen=""
				:src="current.url"
			>
			</iframe>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

let params = {
	iframe: 'video',
	id:     1,
	sid:    'smotrim',
	isPlay: false,
	muted:  false,
	static: true
}

export default {
	computed: {
		...mapGetters([
			'videoMenu'
		]),
		videos() {
			return this.videoMenu && this.videoMenu.length ? this.videoMenu.map(video => {
				let query = [];

				params.id = video.id;

				for (let param in params) {
					query.push(`${ param }=${ params[param] }`);
				}

				return {
					url:      `player/video.html?${ query.join('&') }`,
					isActive: video.isActive
				}
			}) : [];
		},
		current() {
			return this.videos && this.videos.length && this.videos.find(video => video.isActive)
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/config";

.video-html {

	border-radius: 0 16px 16px 0;
	overflow: hidden;

	@media screen and ($media-mobile) {
		border-radius: 8px;
		margin: 0 16px;
	}

	&__player {
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		background: transparent;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border: 0;
			outline: 0;
		}
	}
}
</style>