<template>
  <div>
    <p>Настоящие Правила обработки данных при проведении информационной кампании в рамках организации мероприятия «Прямая линия с Владимиром Путиным» (далее – Правила, Кампания и Мероприятие соответственно) регламентируют порядок сбора и обработки информации, предоставляемой лицами, участвующими и (или) планирующими участвовать в Мероприятии (далее – Участник).</p>

    <p>Актуальная версия Правил подлежит публикации на официальном интернет-сайте Кампании: moskva-putinu.ru (москва-путину.рф) (далее – Сайт Мероприятия).</p>

    <p>Правила могут быть изменены в одностороннем порядке без направления уведомлений Участникам.</p>

    <h2>Организаторы Кампании</h2>

    <ul>
      <li>Федеральное государственное унитарное предприятие «Всероссийская государственная телевизионная и радиовещательная компания» (ФГУП «ВГТРК»);</li>
      <li>Публичное акционерное общество «Ростелеком» (ПАО «Ростелеком»).</li>
    </ul>

    <h2>Общие сведения о Кампании</h2>

    <p>Кампания проводится на всей территории Российской Федерации в рамках Мероприятия на некоммерческой, добровольной основе.</p>

    <p>Сроки проведения Кампании и Мероприятия публикуются на официальных интернет-сайтах Кампании.</p>

    <p>В рамках Кампании осуществляется прием сообщений от Участников и их последующая обработка в целях подготовки актуальных вопросов для Мероприятия, а также в статистических и исследовательских целях.</p>

    <p>Сбор сообщений Участников осуществляется с использованием Сайта Мероприятия, в том числе при помощи приложения «Москва-Путину», в виде текстовых и видео-вопросов, вопросов со вложенными файлами для их отображения на визуальных элементах программы передач, в том числе в прямом эфире, а также посредством обращения по телефонному номеру 8 (800) 200-40-40 и (или) посредством направления SMS/MMS по номеру 0-40-40.</p>

    <p>Участие в Мероприятии не предполагает возникновения у Участника, чьё сообщение используется в Мероприятии, права на получение какой-либо материальной выгоды, в том числе выплату денежного приза, награды или иного вознаграждения.</p>

    <p>Сообщения Участников могут быть использованы при проведении Мероприятия в любом виде по усмотрению Организаторов.</p>

    <p>Материалы сообщений, представленные Участниками Организаторам в рамках Кампании, не подлежат возвращению Участникам и остаются у Организаторов.</p>

    <p>Организаторы оставляют за собой право на исключение каких-либо сообщений из Мероприятия без объяснения причин, а также оставляют право отказаться от проведения Мероприятия в любое время по своему усмотрению.</p>

    <p>Участник, направляя сообщение установленными Правилами способами, выражает свое согласие с настоящими Правилами.</p>

    <h2>Требования к Участнику Кампании</h2>

    <p>Участие в Кампании является бесплатным.</p>

    <p>Участниками Кампании могут быть все желающие, принимающие условия настоящих Правил и согласные с их содержанием.</p>

    <p>Участником Мероприятия может быть лицо, подавшее обращение в сроки, предусмотренные настоящими Правилами, и отвечающее требованиям настоящих Правил.</p>

    <p>Организаторы оставляют за собой право запросить письменные доказательства указанной в Обращении информации.</p>

    <p>Информация, которая содержится в сообщениях, проверяется по разнообразным источникам, чтобы подтвердить её достоверность.</p>

    <h2>Порядок участия в Кампании</h2>

    <p>Для участия в Кампании Участнику необходимо на Сайте Мероприятия или в мобильном приложении «Москва-Путину» пройти процедуру регистрации путем написания оригинального Логина и Пароля для аутентификации и идентификации лица, принимающего участие в Кампании.</p>

    <p>Устанавливая отметку в соответствующем чек-боксе после заполнения анкеты в поле «Я ознакомлен с Правилами проведения информационной Кампании», Участник соглашается (акцептует) с условиями настоящих Правил и понимает, что таким образом ставит свою простую электронную подпись под условиями оферты, соглашаясь со всеми положениями настоящих Правил.</p>

    <p>После регистрации, путем написания оригинального Логина и Пароля для идентификации лица, Участник наделяется правомочием ставить свою простую электронную подпись, таким образом принимая и соглашаясь с условиями настоящих Правил в электронной форме, которые признаются равнозначными документу на бумажном носителе, подписанному собственноручной подписью.</p>

    <p>Для определения лица, ставящего визу, то есть возможности сопоставления визы и определенного лица (контрагента), на номер телефона Участника отправляется пароль, который необходимо ввести в специальной графе на сайте или в мобильных приложениях. Сопоставление визы и определенного лица может быть аналогично подтверждено аккаунтом в социальных сетях.</p>

    <p>Участник подтверждает, что все отправления с таких адресов считаются завизированными простой электронной подписью.</p>

    <p>Участник обязан сохранить конфиденциальность своей простой электронной подписи (логин и пароль) и не вправе передавать её третьим лицам.</p>

    <p>Для подачи сообщения на Сайте мероприятия или в мобильном приложении «Москва-Путину» Участник должен заполнить анкету (профиль), а также непосредственно загрузить сообщение.</p>

    <p>Анкетная информация используется Организаторами для целей проведения Мероприятия и для связи с Участником. Данная информация остается доступной только Организаторам, кроме случаев, оговоренных в настоящих Правилах.</p>

    <p>При организации Мероприятия Организаторы вправе указать информацию об Участнике: ФИО Участника, возраст, профессию и иную переданную Участником информацию по усмотрению Организаторов.</p>

    <p>Каждый Участник может направить неограниченное количество сообщений.</p>

    <p>На Мероприятие не принимаются материалы, уличенные в плагиате, содержащие сцены насилия, обнаженную натуру, демонстрирующие либо призывающие к нарушению общепринятых норм морали, или нарушающие личное достоинство, честь человека (людей), либо затрагивающие религиозные или национальные культурные ценности, выражающие агрессию, пропагандирующие экстремизм, насилие, потребление наркотиков, алкогольных напитков и т.п.</p>

    <p>Организаторы вправе использовать представленные материалы без указания имени Участника и имени автора (или иного правообладателя), а также без выплаты авторского вознаграждения.</p>

    <h2>Обработка персональных данных Участников</h2>

    <p>Обработка персональных данных Участников осуществляется в соответствии с законодательством Российской̆ Федерации и Условиями обработки персональных данных. </p>

    <h2>Лицензируемые Участником права на использование Материалов Обращения, согласие на использование изображения гражданина и на обработку персональных данных</h2>

    <p>Принимая условия настоящих Правил, Участник гарантирует, что:</p>

    <ul>
      <li>обладает исключительным правом в полном объеме на Материалы (аудиовизуальные материалы, фотографии и/или текстовое сообщение) и не передал данное исключительное право третьим лицам и/или не обременил каким-либо образом;</li>
      <li>Материалы не нарушают законные права третьих лиц, включая, но не ограничиваясь, вещные права, интеллектуальные права, личные, гражданские, договорные и иные права, возникающие из любого вида сделок, нормативно-правовых и иных актов, судебных решений и иных оснований, а также не наносят ущерба чести, достоинству и/или деловой репутации третьих лиц и не содержит никаких незаконных и/или запрещенных к обнародованию материалов, от всех лиц, чьи изображения используются в Материалах, получены разрешения на такое использование;</li>
      <li>действует добросовестно и не злоупотребляет правом, в связи с чем факт установки со своего внешнего частного IP-адреса отметки в чек-боксе «Я согласен с «Правилами проведения информационной Кампании» и отправка Материалов лицом, не имеющим на то права, но которое фактически приняло Правила таким образом, не является основанием для признания такого согласия Участника недействительным, а использование Материалов и содержащихся в них изображениях граждан контрафактным.</li>
    </ul>

    <p>В соответствии со ст. 152.1. ГК РФ Участник дает Организатору свое согласие на неоднократное использование своих изображений, содержащихся в Материалах, их обнародование и дальнейшее их использование без ограничения территории и срока использования полностью или фрагментарно любыми не противоречащими действующему законодательству РФ способами, включая, но не ограничиваясь, следующими способами: воспроизводить, распространять, доводить до всеобщего сведения, в том числе, в сети Интернет, сообщать в эфир и по кабелю, полностью или фрагментарно, в цвете или нет, обрабатывать Материалы, содержание изображения, ретушировать, затемнять, искажать и изменять изображения в процессе подготовки окончательного варианта Материалов – в целях проведения Мероприятия, включая, но не ограничиваясь, любыми способами, указанными в ст. 1270 ГК РФ, в целях анонсирования и рекламирования Мероприятия в составе любых средств массовой информации, а также любыми способами наружной рекламы, с правом передавать права на использование изображений третьим лицам.</p>

    <p>Исключительное право на объекты интеллектуальной деятельности, в которых используются Материалы, персональные данные, сведения о частной жизни в полном объеме принадлежат Организаторам, которые вправе распоряжаться ими и использовать их без каких-либо ограничений, в том числе передавать их третьим лицам.</p>

    <p>Участник принимает на себя ответственность за содержание Материалов и анкеты, в связи с чем обязуется в случае возникновения требований, претензий и/или исков со стороны третьих лиц, оспаривающих обладание исключительным и/или иными правами на Материалы либо возникших в связи с их использованием, урегулировать эти требования, претензии и/или иски своими силами и за свой счет, а в случае возникновения у Организаторов убытков, возместить их Организаторам в полном объеме.</p>

    <p>Ответственность за нарушения прав третьих лиц (в том числе, авторских, смежных и иных прав третьих лиц), допущенных Участниками в связи с предоставлением в рамках Кампании и Мероприятия Материалов, несут сами Участники. Организаторы не несут какой-либо имущественной или неимущественной ответственности ни перед Участником, ни перед третьими лицами, права которых нарушены.</p>

    <p>Принимая участие в Мероприятии и Кампании, Участники соглашаются с тем, что их имена и фамилии, фото- и аудио-визуальные материалы могут быть использованы публично, без дополнительного согласия Участников и без уплаты им какого-либо дополнительного вознаграждения. Организаторы оставляют за собой право использовать Материалы с целью популяризации Кампании и Мероприятия путем размещения на сайтах Организаторов, или учредителем и соучредителем которых они являются, хранения и использования в печатной и другой рекламной продукции.</p>

    <p>Настоящее заявление (согласие) действует бессрочно на территории стран всего мира.</p>

    <p>Настоящие Правила являются публичной офертой. Лицо, ставящее свою простую электронную подпись в настоящих Правилах, акцептует данные Правила.</p>

    <p>Контактные данные: 115162, г. Москва, ул. Шаболовка, д. 37, электронный адрес: support@moskva-putinu.ru</p>

    <p>Авторское право на проведение Кампании и Мероприятия принадлежит Организаторам.</p>

    <p>Вопросы, собранные в рамках Мероприятия и Кампании, не являются официальными обращениями в адрес Президента. Отправить официальное обращение Президенту Российской Федерации можно на сайте Управления по работе с обращениями граждан и организаций по адресу http://letters.kremlin.ru/.</p>
  </div>
</template>

<script>
export default {};
</script>
