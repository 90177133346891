<template>
	<section :class="applicationClass">
		<section :class="controlClass">
			<section class="app__wrapper">
				<app-header/>
				<app-control/>
				<mobile-control/>
			</section>
		</section>
		<section :class="contentClass">
			<ask-question type="mobile"/>
			<app-article
				v-for="section in sections"
				:color="section.color"
				:title="section.title"
				:type="section.type"
				:subtype="section?.subtype"
			>
				<template v-if="section.menu && section.menu.length > 1" v-slot:menu>
					<ul>
						<li v-for="item in section.menu">
						<span
							:class="{
								'active': item.isActive
							}"
							v-if="item.view"
							v-text="item.title"
							@click.prevent="setMenuActive({
								type: section.type,
								item: item
							})"
						/>
							<a
								v-if="item.href"
								v-text="item.title"
								:href="item.href"
								target="_blank"
							/>
						</li>
					</ul>
				</template>
			</app-article>
		</section>
		<router-view/>
		<div class="rules-link-block">
      <div class="control__anons"></div>
      <div class="control__menu">
      <router-link
				@click="showPopupIfPossible" 
				:to="{ name: 'rules' }"
			>Правила проведения информационной кампании в рамках организации мероприятия «Прямая линия с Владимиром Путиным»</router-link>
      </div>
		</div>
		<popup v-if="isPopupOpened" />
	</section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppHeader from './components/AppHeader'
import AppControl from './components/AppControl'
import AppArticle from './components/AppArticle'
import MobileControl from './components/MobileControl'
import AskQuestion from './components/Ask'
import Popup from './components/Popup'

export default {
	components: {
		AppArticle,
		AppControl,
		AppHeader,
		MobileControl,
		AskQuestion,
		Popup
	},
	computed:   {
		...mapGetters([
			'currentArticle',
			'sections',
			'isPopupOpened',
			'filteredMobileSections'
		]),
		applicationClass() {
			let classNames = [ 'app' ];
			let status     = this.currentArticle === 'home' ? 'home' : 'page';

			classNames.push(`app--${ status }`);

			return classNames.join(' ');
		},
		controlClass() {
			let classNames = [ 'app__control' ];
			let status     = this.currentArticle === 'home' ? 'active' : 'hidden';

			classNames.push(`app__control--${ status }`);

			return classNames.join(' ');
		},
		contentClass() {
			let classNames = [ 'app__content' ];
			let status     = this.currentArticle === 'home' ? 'hidden' : 'active';

			classNames.push(`app__content--${ status }`);

			return classNames.join(' ');
		}
	},
	methods:    {
		...mapActions([
			'setMenuActive',
			'showPopup'
		]),
		showPopupIfPossible(event) {
			if (window.innerWidth <= 768) {
				event.preventDefault();
				event.stopPropagation();
				this.showPopup({type: true, page: 'rules'});	
			}
		}	
	}
}
</script>

<style lang="scss">
@import "assets/scss";

.app {
	position: relative;
	max-width: 1440px;
	margin: 0 auto;

	&:before {
		content: '';
		position: fixed;
		z-index: 1;
		bottom: 10%;
		left: 0;
		width: 200%;
		height: 0;
		padding: 0 0 100%;
		background: transparent url(./assets/images/background.jpg) 0 0 / cover no-repeat;

		@media screen and ($media-mobile) {
			bottom: 20%;
		}
	}

	&--home {
		&:before {
			transform: translate(0, 50%);
			transition: transform .5s ease 0s;
		}
	}
	&--page {
		&:before {
			transform: translate(-50%, 0);
			transition: transform .5s ease 0s;

			@media screen and ($media-mobile) {
				transform: translate(0, 50%);
			}
		}
	}

	&__control {
		position: relative;
		overflow: hidden;
		z-index: 2;

		&--active {
			max-height: 3000px;
			transition: max-height .5s ease 0s;
		}

		&--hidden {
			max-height: 32px;
			transition: max-height .5s ease 0s;

			@media screen and ($media-mobile) {
				max-height: 3000px;
			}
		}
	}

	&__content {
		position: relative;
		overflow: hidden;
		z-index: 2;

		&--active {
			opacity: 1;
			transform: translateY(0);
			transition: opacity .5s ease .25s;

			@media screen and ($media-mobile) {
				.article--privacy,
				.article--rules {
					display: none;
				}

			}
		}

		&--hidden {
			opacity: 0;
			transform: translateY(20%);
			transition: opacity .5s ease 0s;

			@media screen and ($media-mobile) {
				opacity: 1;
				transform: translateY(0);

				.article--privacy, 
				.article--rules {
					display: none;
				}
			}
		}
	}

	&__wrapper {
		.app__control--active & {
			transform: translateY(0);
			transition: transform .5s ease 0s;
		}

		.app__control--hidden & {
			transform: translateY(-97.5%);
			transition: transform .5s ease 0s;

			@media screen and ($media-mobile) {
				transform: translateY(0);
			}
		}
	}

	.rules-link-block {
		position: relative;
		text-align: center;
		text-decoration: underline;
		z-index: 1;
		padding: 0 20px 20px;
    display: flex;

    .control__menu {
      @media screen and ($media-desktop) {
        width: 100%;
      }
    }

		a {
			font-family: 'GolosText Regular';
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			color: #2d3b57;
		}

	}
}

 .app__content--active + .rules-link-block {
 	display: none;
 }

.no-scroll {
	overflow: hidden;
}
</style>