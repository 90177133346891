<template>
	<div class="archive-html">
		<div class="archive-html__anons" v-html="anons"/>
		<div class="archive-html__content">
			<ul class="archive-html__list">
				<li class="archive-html__item" v-for="date in dates">
					<a
						class="archive-html__link"
						:href="date.href"
						v-text="date.title"
						target="_blank"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>

let data = [ ...document.querySelectorAll('.diagram-block') ].map($block => {
	return {
		href:  $block.querySelector('.diagram-block__line').getAttribute('href'),
		title: $block.querySelector('.diagram-block__year').innerText
	}
})

export default {
	data() {
		return {
			anons: '',
			dates: [
        {href: 'https://2023.moskva-putinu.ru/', title: '2023'},
        {href: 'https://2021.moskva-putinu.ru/', title: '2021'},
        {href: 'https://2020.moskva-putinu.ru/', title: '2020'},
				{href: 'https://2019.moskva-putinu.ru/', title: '2019'},
				{href: 'https://2018.moskva-putinu.ru/', title: '2018'},
				{href: 'https://2017.moskva-putinu.ru/', title: '2017'},
				{href: 'https://2016.moskva-putinu.ru/', title: '2016'},
				{href: 'https://2015.moskva-putinu.ru/', title: '2015'},
				{href: 'https://2014.moskva-putinu.ru/', title: '2014'},
				{href: 'https://2013.moskva-putinu.ru/', title: '2013'},
				{href: 'https://2011.moskva-putinu.ru/', title: '2011'},
				{href: 'https://2010.moskva-putinu.ru/', title: '2010'},
				{href: 'https://2009.moskva-putinu.ru/', title: '2009'},
				{href: 'https://2008.moskva-putinu.ru/', title: '2008'},
				{href: 'https://www.president-line.ru/', title: '2007'},
				{href: 'https://www.liniya2006.ru/', title: '2006'},
				{href: 'https://www.liniya2005.ru/', title: '2005'},
				{href: 'https://www.linia2003.ru/', title: '2003'},
				{href: 'https://www.linia2002.ru/', title: '2002'},
				{href: 'https://www.ortrtr.ru/', title: '2001'}
			]
		}
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import "../assets/scss/config";

.archive-html {
	&__anons {
		font: 20px/28px 'RF Devi Semi Bold', sans-serif;
		color: $color-white;
		margin: 95px 0 50px;

		@media screen and ($media-mobile) {
			font: 16px/24px 'RF Devi Semi Bold', sans-serif;
			margin: 15px 16px 26px;
		}
	}

	&__content {
		margin: 0 (-$grid-gutter-half);
		padding: 56px 0 0;
		position: relative;

		@media screen and ($media-mobile) {
			padding: 0;
			margin: 0;
			overflow: hidden;

			::-webkit-scrollbar {
				width: 0; /* Remove scrollbar space */
				background: transparent; /* Optional: just make scrollbar invisible */
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			height: 2px;
			left: calc(#{math.div(-3 * 100%, 7)} + #{$grid-gutter-half});
			width: calc(#{math.div(12 * 100%, 7)} - #{$grid-gutter-full});
			background: $color-white;

			@media screen and ($media-desktop) {
				left: calc(#{math.div(-4 * 100%, 6)} + #{$grid-gutter-half});
				width: calc(#{math.div(12 * 100%, 6)} - #{$grid-gutter-full});
			}

			@media screen and ($media-mobile) {
				display: none;
			}
		}
	}

	&__list {
		margin: (-8px) math.div($grid-gutter-half, 2);
		padding: 0;
		list-style: none;
		display: flex;
		flex-flow: row wrap;

		@media screen and ($media-mobile) {
			flex-flow: row nowrap;
			width: 100%;
			overflow: auto;
			margin: 0;
			padding: 0;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&__item {
		margin: 8px;

		@media screen and ($media-mobile) {
			font: 20px/40px 'RF Devi Semi Bold', sans-serif;
			margin: 0 4px;

			&:first-child {
				margin: 0 4px 0 16px;
			}

			&:last-child {
				margin: 0 16px 0 4px;
			}
		}
	}

	&__link {
		font: 32px/48px 'RF Devi Regular', sans-serif;
		color: $color-white;
		text-decoration: none;
    padding: 0 32px 0 16px;
		display: block;
		position: relative;
		border-radius: 48px;
		box-sizing: border-box;
		border: 2px solid $color-white;

    @media screen and ($media-mobile) {
      padding: 0 16px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 13px;
      right: 16px;
      height: 8px;
      width: 8px;
      background: transparent url(../assets/images/arr_w.png) center / cover no-repeat;

      @media screen and ($media-mobile) {
        content: none;
      }
    }

		@media screen and ($media-mobile) {
			font: 20px/40px 'RF Devi Semi Bold', sans-serif;
			border-radius: 40px;

		}

		&:hover {
			color: $color-grey;
			background: $color-white;

      &:after {
        background: transparent url(../assets/images/arr_b.png) center / cover no-repeat;
      }

			@media screen and ($media-mobile) {
				padding: 0 16px;
				color: $color-white;
				background: transparent;
			}
		}
	}
}
</style>