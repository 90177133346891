<template>
	<div class="live-html">
		<div class="live-html__player" v-if="lives && lives.length">
			<iframe
				name="live_player"
				allow="autoplay; encrypted-media; fullscreen; clipboard-read; clipboard-write"
				allowfullscreen=""
				:src="current.url"
			>
			</iframe>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

let params = {
	iframe: 'live',
	id:     1,
	sid:    'smotrim',
	isPlay: true,
	muted:  false,
	static: true
}

export default {
	computed: {
		...mapGetters([
			'liveMenu'
		]),
		lives() {
			return this.liveMenu && this.liveMenu.length ? this.liveMenu.map(live => {
				let query = [];

				params.id = live.id;

				for (let param in params) {
					query.push(`${ param }=${ params[param] }`);
				}

				return {
					url:      `player/live.html?${ query.join('&') }`,
					isActive: live.isActive
				}
			}) : [];
		},
		current() {
			return this.lives && this.lives.length && this.lives.find(live => live.isActive)
		}
	}
}
</script>

<style lang="scss">
@import "../assets/scss/config";

.live-html {

	border-radius: 0 16px 16px 0;
	overflow: hidden;

	@media screen and ($media-mobile) {
		border-radius: 8px;
		margin: 0 16px;
	}

	&__player {
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		background: transparent;
		position: relative;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border: 0;
			outline: 0;
		}
	}
}
</style>