import { createApp } from 'vue'
import store from './store'
import router from './router'
import App from './App'

const $app = document.getElementById('app');

store.dispatch('setCurrentStage')
	.then(() => {
		createApp(App)
			.use(router)
			.use(store)
			.mount($app);
	});