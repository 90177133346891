<template>
	<article :class="className">
		<h1 class="article__title" v-if="title" v-text="title"/>
		<div class="article__control">
			<router-link
				class="article__button"
				to="/"
			/>
		</div>
		<div class="article__menu">
			<slot name="menu"/>
		</div>
		<div class="article__content">
			<component :is="subtype || type"/>
		</div>
	</article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Archive from '../data/Archive'
import Live from '../data/Live'
import Questions from '../data/Questions'
import Transcript from '../data/Transcript'
import Videos from '../data/Videos'
import Privacy from '../data/Privacy'
import Rules from '../data/Rules'

export default {
	components: {
		Archive,
		Live,
		Questions,
		Transcript,
		Videos,
    	Privacy,
		Rules
	},
	props:      {
		title:   String,
		color:   String,
		type:    String,
		subtype: String
	},
	computed:   {
		...mapGetters([
			'currentArticle'
		]),
		className() {
			let classes = [ 'article' ];

			classes.push(`article--${ this.type }`)

			if (this.color) {
				classes.push(`article--${ this.color }`)
			} else {
				classes.push(`article--light`)
			}

			if (this.type === this.currentArticle) {
				classes.push('article--active');
			} else {
				classes.push('article--hidden');
			}

			return classes.join(' ');
		}
	},
	methods:    {
		...mapActions([
			'setArticle'
		])
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import "../assets/scss/config";

.article {
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	overflow: hidden;
	z-index: 2;

	&--active {
		margin: 40px $grid-gutter-page;
		padding: 0 0 50px;
		max-height: 3000px;
		transition: max-height 0s ease 0s, padding-bottom 0s ease 0s, margin 0s ease 0s;

		@media screen and ($media-mobile) {
			max-height: none;
		}
	}

	&--hidden {
		margin: 0 $grid-gutter-page;
		padding: 0;
		max-height: 0;
		transition: max-height 0s ease .5s, padding-bottom 0s ease .5s, margin 0s ease .5s;

		@media screen and ($media-mobile) {
			max-height: none;
		}
	}

	&--live,
	&--videos {
		padding: 0;

		@media screen and ($media-mobile) {
			padding: 0 0 32px;
		}
	}

	@media screen and ($media-mobile) {
		margin: 16px 0;
		padding: 0 0 32px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: $grid-gutter-half;
		width: calc(100% - #{$grid-gutter-full});
		height: 100%;
		border-radius: 16px;
		z-index: 1;
	}

	&--red {
		color: $color-white;

		&:before {
			background: $color-red;
		}
	}

	&--blue {
		color: $color-white;

		&:before {
			background: rgba($color-blue, .9);
		}
	}

	&--dark {
		color: $color-white;

		&:before {
			background: rgba($color-grey, .98);
		}
	}

	&--light {
		color: $color-grey;

		&:before {
			background: rgba($color-light, .9);

			@media screen and ($media-mobile) {
				background: rgba($color-light, 1);
			}
		}
	}

	&__title {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(9 * 100%, 12);
		order: 2;
		z-index: 3;

		font: 32px/ 40px 'RF Devi Semi Bold', sans-serif;
		margin: 32px auto 32px 0;

		.article--live &,
		.article--videos & {
			order: 4;
			display: none;

			@media screen and ($media-mobile) {
				display: block;
				order: 2;
			}
		}

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(8 * 100%, 12);
		}

		@media screen and ($media-mobile) {
			font: 24px/24px 'RF Devi Semi Bold', sans-serif;
			letter-spacing: -0.01em;
			flex: 0 1 100%;
			margin: 26px 16px 21px;
		}
	}

	&__control {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(3 * 100%, 12);
		order: 1;
		z-index: 3;

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(4 * 100%, 12);
		}

		@media screen and ($media-mobile) {
			display: none;
		}
	}

	&__menu {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(3 * 100%, 12);
		order: 3;
		z-index: 4;

		.article--live &,
		.article--videos & {
			position: absolute;
			width: math.div(3 * 100%, 12);
			top: 104px;
			left: 0;
			overflow: auto;
			height: calc(100% - 136px);

			@media screen and ($media-desktop){
				width: math.div(4 * 100%, 12);
			}

			@media screen and ($media-mobile) {
				width: auto;
				position: static;
				top: auto;
				left: auto;
				margin: 0 0 32px;
			}
		}

		.article--transcript & {
			order: 4;
			display: none;
		}

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(4 * 100%, 12);
		}

		@media screen and ($media-mobile) {
			flex: 0 1 100%;
			overflow: hidden;

			::-webkit-scrollbar {
				width: 0; /* Remove scrollbar space */
				background: transparent; /* Optional: just make scrollbar invisible */
			}
		}

		ul {
			margin: 0 32px;
			padding: 0;
			list-style: none;

			@media screen and ($media-mobile) {
				display: flex;
				flex-flow: row nowrap;
				width: 100%;
				overflow: auto;
				margin: 0;

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		li {
			font: 16px/32px 'RF Devi Semi Bold', sans-serif;
			margin: 0;

			.article--live & {
				font: 16px/24px 'RF Devi Semi Bold', sans-serif;
				margin: 0 0 12px;
				@media screen and ($media-mobile) {
					font: 16px/32px 'RF Devi Semi Bold', sans-serif;
					margin: 0;
				}
			}

      .article--videos & {
        font: 32px/48px 'GolosText Regular', sans-serif;
        margin: 0 0 24px;
        @media screen and ($media-mobile) {
          font: 16px/32px 'RF Devi Semi Bold', sans-serif;
          margin: 0;
        }
      }

			@media screen and ($media-mobile) {
				font: 20px/40px 'RF Devi Semi Bold', sans-serif;
				margin: 0 4px;

				&:first-child {
					margin: 0 4px 0 16px;
				}

				&:last-child {
					margin: 0 16px 0 4px;
				}
			}
		}

		a, span {
      text-decoration: underline;
			color: inherit;
			cursor: pointer;

			@media screen and ($media-mobile) {
				text-decoration: none;
				white-space: nowrap;
				display: block;
				border: 2px solid $color-white;
				padding: 0 16px;
				border-radius: 40px;

				.article--videos &.active {
					background: $color-white;
					color: $color-blue;
				}
			}
		}

    span {
      .article--videos & {
        box-sizing: border-box;
        display: inline-block;
        border-radius: 24px;
        padding: 0 16px;
        border: 2px solid $color-white;
        text-decoration: none;
      }
    }

		a[target=_blank] {
			background: transparent url(../assets/images/arr.svg) right center no-repeat;
			padding-right: 15px;
			@media screen and ($media-mobile) {
				background: 0;
				padding: 0 16px;
			}
		}

    span.active {
      text-decoration: none;
      .article--videos & {
        background: $color-white;
        color: #3F81FF;
      }
    }
	}

	&__content {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(9 * 100%, 12);
		order: 4;
		z-index: 3;

		.article--live &,
		.article--videos & {
			order: 2;

			@media screen and ($media-mobile) {
				order: 4;
			}
		}

		.article--transcript & {
			flex: 0 0 math.div(12 * 100%, 12);
			order: 3;
		}

		@media screen and ($media-desktop) {
			flex: 0 0 math.div(8 * 100%, 12);
		}

		.article--archive & {
			flex: 0 0 math.div(7 * 100%, 12);
			margin: 0 math.div(2 * 100%, 12) 0 0;

			@media screen and ($media-desktop) {
				flex: 0 0 math.div(6 * 100%, 12);
				margin: 0 math.div(2 * 100%, 12) 0 0;
			}

			@media screen and ($media-mobile) {
				flex: 0 1 100%;
				margin: 0;
			}
		}

		@media screen and ($media-mobile) {
			flex: 0 1 100%;
			overflow: hidden;
		}

		.article--ask & {
			position: absolute;
			bottom: 24px;
			left: 0;
			z-index: 5;
		}
	}

	&__button {
		-webkit-appearance: none;
		border: 0;
		outline: 0;
		cursor: pointer;

		.article__control & {
			display: block;
			height: 40px;
			width: 40px;
			background: transparent url(../assets/images/close-light.svg) center / cover no-repeat;
			margin: 32px auto 32px 32px;
		}

		.article--light .article__control & {
			background: transparent url(../assets/images/close-dark.svg) center / cover no-repeat;
		}
	}
}
</style>