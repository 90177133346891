<template></template>

<script>
import { mapActions } from 'vuex'

export default {
	methods: {
		...mapActions([
			'setArticle'
		])
	},
	async mounted() {
		let name = this.$route.name;

		document.title = this.$route.meta.title;

		await this.setArticle({
			type: name
		});

		let liveFrame = window.frames?.live_player;
		let videoFrame =window.frames?.video_player;

		if (liveFrame) {
			liveFrame.postMessage('play', '*');
		}

		if (videoFrame) {
			videoFrame.postMessage('pause', '*');
		}
	}
}
</script>